import { useState, useEffect } from 'react';

export const DetailArticle = ({ template_name }: any) => {
  const [workflow, setWorkflow] = useState<any>('');
  console.log(template_name);
  const nameChanger = (template_name: any) => {
    if (template_name == 'Guest Management') {
      return 'Guest';
    } else if (template_name == 'User Management') {
      return 'User';
    } else {
      return 'hidden';
    }
  };
  useEffect(() => {
    setWorkflow(nameChanger(template_name));
  }, []);
  return (
    <div className={`ml-20 ${workflow} max-w-3xl mt-4`}>
      <p className="text-xl font-bold">First Steps 🚀</p>
      <p className="text-base mt-4 text-gray-500">
        Welcome to the Asana {workflow} Management Workflow! This workflow is designed to help Asana
        users easily manage {workflow}s in their Asana projects.
      </p>

      <p className="text-base mt-4 text-gray-500">Here how it works:</p>
      <li className="text-base mt-4 text-gray-500">
        New {workflow}s: You’ll be informed when a new {workflow} joins your workspace.
      </li>
      <li className="text-base mt-4 text-gray-500">
        Managing {workflow} Access: Once a {workflow} has been added to a project, the project
        creator can manage their purpose and teams by clicking on the tasks and seeing details.{' '}
      </li>
      <li className="text-base mt-4 text-gray-500">
        Removing {workflow}s: To remove a {workflow} from a project, click on the “Approve or
        Reject” custom field. You can then choose to remove them from the project.{' '}
      </li>
      <p className="text-base mt-4 text-gray-500">
        By following these simple steps, youll be able to easily manage and collaborate with guests
        in your Asana projects. Happy collaborating!
      </p>
    </div>
  );
};
