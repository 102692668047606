import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

import { AsanaAuthInterface } from '../types';

type AsanaLoginProps = {
  onSuccess: () => void;
};

export const AsanaAuth = ({ onSuccess }: AsanaLoginProps) => {
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState<AsanaAuthInterface>();
  const { asanaLogin } = useAuth();
  const cookieValue = window.localStorage.getItem('asanaAppAuthState');
  const state = searchParams.get('state');
  const getCode = searchParams.get('code');
  const formattedCode = { code: getCode };
  const loginAsana = async () => {
    setCode(formattedCode);
    if (cookieValue == state) {
      console.log(code);
      await asanaLogin(formattedCode);
      onSuccess();
    }
  };

  useEffect(() => {
    loginAsana();
  }, []);
  return <div className="h-screen w-screen bg-white"></div>;
};
