import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import { Button } from '@/components/Elements';
import Sidebar from '@/components/Elements/Sidebar/Sidebar';
import { deleteMasterWorkflow } from '@/features/workflow/api/deleteWorkflow';
import CustomButton from '@/features/workflow/components/CustomButton';

const Container = tw.div`
md:pl-64
flex
mx-20

`;

const makeDateFormat = (time: any) => {
  const date = new Date(time);
  const formattedDate = date.toISOString().split('T')[0].replace(/-/g, '/');
  return formattedDate;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function GridList({ workflows }: any) {
  const onClick = async (workflow_id) => {
    await deleteMasterWorkflow(workflow_id);
    // // window.location.reload();
  };

  return (
    <div className="w-full ">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Your Workflows</h1>
            <p className="mt-2 text-sm text-gray-700">You can manage your workflows here!</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
        </div>
        <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
          <table className="bg-white min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Resource
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Credit
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Workflow Type
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Create date
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Select</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {workflows.map((workflow, planIdx) => (
                <tr key={workflow.id}>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-transparent',
                      'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                    )}
                  >
                    <div className="font-medium text-gray-900">{workflow.name}</div>
                    <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden"></div>
                    {planIdx !== 0 ? (
                      <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                    ) : null}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    <a
                      className="text-indigo-500"
                      href={`https://app.asana.com/0/${workflow.resource_id}`}
                    >
                      {' '}
                      {workflow.resource}
                    </a>
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {workflow.credit}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {workflow.workflow_type}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-gray-200',
                      'px-3 py-3.5 text-sm text-gray-500'
                    )}
                  >
                    <div className="sm:hidden">{makeDateFormat(workflow.create_date)}</div>
                    <div className="hidden sm:block">{makeDateFormat(workflow.create_date)}</div>
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-transparent',
                      'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
                    )}
                  >
                    {workflow.webhook_id ? (
                      <button
                        type="button"
                        onClick={() => onClick(workflow.master_workflow_id)}
                        className="inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-400 "
                      >
                        Delete<span className="sr-only">, {workflow.name}</span>
                      </button>
                    ) : (
                      ''
                    )}
                    {planIdx !== 0 ? (
                      <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function SkelatonGridList() {
  return (
    <div className="mt-20 w-full px-4 sm:px-6 lg:px-8">
      <div className="animate-pulse bg-gray-200 w-full border-gray-100 space-x-3 h-60 lg:h-72 rounded-lg  dark:bg-gray-900"></div>
    </div>
  );
}
export const WorkflowList = ({ workflows }: any) => {
  const navigate = useNavigate();

  // Change page

  return (
    <div className="bg-gray-50 h-screen">
      <Sidebar />
      <div className="bg-white">
        <Container>
          <Button
            disabled={true}
            className="w-full  bg-white hover:bg-white text-black font-bold"
            size="lg"
            startIcon={
              <DocumentDuplicateIcon
                className="ml-1.5 mb-1 text-black inline-flex h-7 w-7"
                aria-hidden="true"
              />
            }
            onClick={() => {}}
          >
            Workflows
          </Button>
        </Container>
      </div>
      <p className=" px-4 border-t w-full border-gray-200 "></p>

      {workflows.length > 0 ? (
        workflows[0] == 'Empty' ? (
          <div className="ml-60 mt-40 gap-4 flex flex-col items-center text-center justify-center">
            <p className="flex-1 ">
              You have not any workflow yet 😔 You can create your first workflow by
            </p>
            <CustomButton
              option="flex-1 text-center  bg-green-500"
              onClick={() => {
                navigate('/app');
              }}
            >
              Create Your First Workflow
            </CustomButton>
          </div>
        ) : (
          <div className="flex mt-12 ml-4 justify-center flex-col   ">
            <Container>
              <GridList workflows={workflows} />
            </Container>
          </div>
        )
      ) : (
        <Container>
          <SkelatonGridList />
        </Container>
      )}
    </div>
  );
};
