import { LockClosedIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import { checkAsanaConnection } from '@/features/auth';

import AsanaConnectionComponent from '../Alert/asanaConnectAlert';
import { Button } from '../Button';
import AsanaIcon from '../Icons/asanaIcon';

const CardContainer = tw.div`
    flex 
    flex-col 
    justify-between
    gap-6
    items-end 
    self-stretch 
    flex-grow 
    px-4 
    py-5 
    bg-white
    border-b 
    border-gray-200
    max-w-md
    h-72

    
`;

const DescriptionWrapper = tw.div`
    flex 
    flex-col 
    justify-start 
    items-start 
    self-stretch 
    flex-grow-0 
    flex-shrink-0 
    relative 
    gap-4
`;

const CardTitle = tw.h2`
    text-lg 
    font-medium 
    text-left 
    text-gray-900
`;

const CardDescription = tw.p`
    self-stretch 
    flex-grow-0 f
    lex-shrink-0
    text-sm 
    text-left 
    text-gray-500
`;

const ButtonContainer = tw.div`
  flex 
  justify-start 
  items-start 
  text-black
  flex-grow-0 
  flex-shrink-0 
  gap-3.5
`;

export const TemplateCard = ({ id, title, description, status, Icon }: any) => {
  console.log(id, title, description);

  const [asanaModal, setAsanaModal] = useState(false);
  const navigate = useNavigate();
  const onClick = async () => {
    console.log(1);
    const data = await checkAsanaConnection();
    if (data) {
      setAsanaModal(false);
      navigate(`/workflow/create/template/${id}`);
    } else {
      setAsanaModal(true);
    }
  };

  return (
    <div>
      {asanaModal ? (
        <AsanaConnectionComponent asanaModal={asanaModal} setAsanaModal={setAsanaModal} />
      ) : (
        ''
      )}
      <CardContainer>
        <DescriptionWrapper>
          <div className="inline-flex">
            <div className="bg-indigo-500 hover:bg-indigo-600 rounded-lg h-9 w-9">
              <Icon className="ml-1.5 text-white inline-flex mt-1.5 h-6 w-6" aria-hidden="true" />
            </div>
            <div className="ml-2 inline-flex rounded-lg h-8 w-8">
              <AsanaIcon />
            </div>
          </div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </DescriptionWrapper>
        <ButtonContainer>
          <Button
            size="sm"
            endIcon={
              status ? (
                <PlusCircleIcon className="h-4 w-4" aria-hidden="true" />
              ) : (
                <LockClosedIcon className="h-4 w-4" aria-hidden="true" />
              )
            }
            variant={status ? 'primary' : 'inactive'}
            onClick={onClick}
            disabled={!status}
          >
            {status ? 'Start' : 'Coming Soon'}
          </Button>
        </ButtonContainer>
      </CardContainer>
    </div>
  );
};

export const TemplateCardNotFromBackend = ({ title, description, status, Icon, Platform }: any) => {
  return (
    <div>
      <CardContainer>
        <DescriptionWrapper>
          <div className="inline-flex">
            <div className="bg-indigo-500 mt-1.5 hover:bg-indigo-600 rounded-lg h-9 w-9">
              <Icon className="ml-1.5 text-white inline-flex mt-1.5 h-6 w-6" aria-hidden="true" />
            </div>
            <div className="ml-2 inline-flex rounded-lg h-9 w-9">
              <Platform />
            </div>
          </div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </DescriptionWrapper>
        <ButtonContainer>
          <Button
            size="sm"
            endIcon={
              status ? (
                <PlusCircleIcon className="h-4 w-4" aria-hidden="true" />
              ) : (
                <LockClosedIcon className="h-4 w-4" aria-hidden="true" />
              )
            }
            variant={status ? 'primary' : 'inactive'}
            onClick={() => {}}
            disabled={!status}
          >
            {status ? 'Start' : 'Coming Soon'}
          </Button>
        </ButtonContainer>
      </CardContainer>
    </div>
  );
};

export const TemplateCardNotFromBackend2 = ({
  title,
  description,
  status,
  Icon,
  Platform,
}: any) => {
  return (
    <div>
      <CardContainer>
        <DescriptionWrapper>
          <div className="inline-flex">
            <div className="bg-indigo-500 mt-1.5 hover:bg-indigo-600 rounded-lg h-9 w-9">
              <Icon className="ml-1.5 text-white inline-flex mt-1.5 h-6 w-6" aria-hidden="true" />
            </div>
            <div className="ml-2  mt-1 inline-flex rounded-lg h-9 w-9">
              <Platform />
            </div>
          </div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </DescriptionWrapper>
        <ButtonContainer>
          <Button
            size="sm"
            endIcon={
              status ? (
                <PlusCircleIcon className="h-4 w-4" aria-hidden="true" />
              ) : (
                <LockClosedIcon className="h-4 w-4" aria-hidden="true" />
              )
            }
            variant={status ? 'primary' : 'inactive'}
            onClick={() => {}}
            disabled={!status}
          >
            {status ? 'Start' : 'Coming Soon'}
          </Button>
        </ButtonContainer>
      </CardContainer>
    </div>
  );
};
