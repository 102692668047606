import { Route, Routes } from 'react-router-dom';

import Billing from '@/features/home/components/Settings/Billing';
import PackagePage from '@/features/home/components/Settings/PackagePage';
import Settings from '@/features/home/components/Settings/Settings';
import { useWorkflow } from '@/features/hooks/WorkflowHook';
import { WorkflowDetailPage } from '@/features/workflow/components/TeamManagment/WorklogDetailPage';

import { WorkflowList } from '../components/WorkflowList';

export const WorkflowRouteList = () => {
  const { workflows } = useWorkflow();
  return (
    <Routes>
      <Route path="/workflows" element={<WorkflowList workflows={workflows} />} />
      {workflows.map((workflow) => (
        <Route
          key={workflow.id}
          path={`/workflows/detail-${workflow.id}`}
          element={<WorkflowDetailPage workflow={workflow} />}
        />
      ))}
      <Route path="/settings" element={<Settings />} />
      <Route path="/settings/billing" element={<Billing />} />
      <Route path="/settings/packages" element={<PackagePage />} />
    </Routes>
  );
};
