import { Route, Routes } from 'react-router-dom';

import { SubscriptionSuccess } from '../components/Subscription/subscriptionSuccess';
import { Workflow } from '../components/workflow';

export const WorkflowRoutes = () => {
  return (
    <Routes>
      <Route path="create" element={<Workflow />} />
    </Routes>
  );
};

export const SubscriptionSuccessRoutes = () => {
  return (
    <Routes>
      <Route path="subscription" element={<SubscriptionSuccess />} />
    </Routes>
  );
};
