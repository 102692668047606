import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { uuid } from 'uuidv4';

import { Mixpanel } from '@/features/hooks/MixpanelHook';
import { useAuth } from '@/lib/auth';

import { updateState } from '../api/checkState';

export const AsanaAppAuth = () => {
  const generatedState = uuid();
  const updateStateFunc = async (generatedState) => {
    await updateState({ state: generatedState });
    window.location.replace(
      `https://app.asana.com/-/oauth_authorize?response_type=code&client_id=${process.env.REACT_APP_ASANA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASE_URI}/check-state&state=${generatedState}`
    );
  };
  useEffect(() => {
    updateStateFunc(generatedState);
  }, []);

  return <div className="text-center">You are connecting to Workino...</div>;
};

export const AsanaAppAuthCallback = () => {
  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const { mixpanel } = Mixpanel();
  mixpanel.track('Asana App Auth', { user: user?.id, email: user?.email });
  const message = searchParams.get('message');
  window.opener.postMessage(message, 'https://app.asana.com');
  window.close();

  return <>Please Wait...</>;
};
