import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';
import tw from 'tailwind-styled-components';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

const StyledTextInput = tw.input`
    shadow-sm 
    focus:ring-indigo-500 
    focus:border-indigo-500
    lock w-full 
    sm:text-sm 
    border-gray-300 
    rounded-md
    mt-1

`;

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
  const { type = 'text', label, className, registration, error } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <StyledTextInput
        type={type}
        className={clsx(
          'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
          className
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
