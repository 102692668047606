import React, { useCallback } from 'react';
import {
  useMutation,
  useQueryClient,
  UseMutateAsyncFunction,
  useQuery,
  QueryObserverResult,
  RefetchOptions,
} from 'react-query';

import {
  loginWithEmailAndPassword,
  UserResponse,
  LoginCredentialsDTO,
  AuthUser,
  RegisterCredentialsDTO,
  registerWithEmailAndPassword,
  getUser,
  AsanaAuthInterface,
  getAsanaToken,
  getAsanaAppToken,
} from '@/features/auth';
import { Mixpanel } from '@/features/hooks/MixpanelHook';
import storage from '@/utils/storage';
// @ts-nocheck

// Interfaces
export interface AuthProviderProps {
  children: React.ReactNode;
}

export interface AuthContextValue {
  user: AuthUser | undefined;
  asanaLogin: UseMutateAsyncFunction<AuthUser, any, AsanaAuthInterface>;
  asanaAppLogin: UseMutateAsyncFunction<AuthUser, any, AsanaAuthInterface>;

  login: UseMutateAsyncFunction<AuthUser, any, LoginCredentialsDTO>;
  register: UseMutateAsyncFunction<AuthUser, any, RegisterCredentialsDTO>;
  logout: UseMutateAsyncFunction<any, any, void, any>;
  isLoggingIn: boolean;
  isRegistering: boolean;
  refetchUser: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<AuthUser, Error>>;
}

// Utils
async function handleUserResponse(data: UserResponse) {
  const { jwt, user } = data;

  storage.setToken(jwt);
  return user;
}

async function LoadUser() {
  if (storage.getToken()) {
    console.log('Load User', storage.getToken());
    const data = await getUser();
    console.log(data);
    return data;
  }

  return null;
}

const handleLoginFormData = (data: LoginCredentialsDTO): FormData => {
  const { email, password } = data;

  const formattedData = { username: email, password };

  const form = new FormData();
  for (const [key, value] of Object.entries(formattedData)) {
    form.append(key, value);
  }

  return form;
};

// Queries
async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(handleLoginFormData(data));
  const user = await handleUserResponse(response);
  const { mixpanel } = Mixpanel();
  mixpanel.identify(user?.id);
  await mixpanel.people.set({ $email: user.email, $user: user.id });
  mixpanel.track('Login', { Login: 'Login', email: user.email, user: user.id });
  return user;
}

async function loginWithAsana(data: AsanaAuthInterface) {
  const response = await getAsanaToken(data);
  const user = await handleUserResponse(response);
  const { mixpanel } = Mixpanel();
  await mixpanel.identify(user?.id);
  await mixpanel.people.set({ $name: user.email, $email: user.email, $user: user.id });
  await mixpanel.track('Login', { Login: 'Asana Login', email: user.email, user: user.id });
  return user;
}

async function loginWithAsanaForApp(data: AsanaAuthInterface) {
  const response = await getAsanaAppToken(data);
  const user = await handleUserResponse(response);
  const { mixpanel } = Mixpanel();
  mixpanel.identify(user?.id);
  await mixpanel.people.set({ $email: user.email, $user: user.id });

  mixpanel.track('Login', { Login: 'Asana App Login', email: user.email, user: user.id });
  return user;
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin as unknown as string);
}

async function registerFn(data: RegisterCredentialsDTO) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  const { mixpanel } = Mixpanel();
  mixpanel.track('Sign Up', { 'Sign Up': 'Sign Up', email: user.email, user: user.id });
  console.log('Sign Up');
  return user;
}

const AuthContext = React.createContext<AuthContextValue | null>(null);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const queryClient = useQueryClient();

  const { data: user, refetch } = useQuery({
    queryKey: 'auth-user',
    queryFn: LoadUser,
  });

  const setUser = useCallback(
    (data: AuthUser) => queryClient.setQueryData('auth-user', data),
    [queryClient]
  );

  // Mutations
  const loginMutation = useMutation({
    mutationFn: loginFn,
    onSuccess: (user) => {
      setUser(user);
    },
  });
  const loginAsanaMutation = useMutation({
    mutationFn: loginWithAsana,
    onSuccess: (user) => {
      setUser(user);
    },
  });

  const loginAsanaAppMutation = useMutation({
    mutationFn: loginWithAsanaForApp,
    onSuccess: (user) => {
      setUser(user);
    },
  });

  const logoutMutation = useMutation({
    mutationFn: logoutFn,
    onSuccess: () => {
      queryClient.clear();
    },
  });

  const registerMutation = useMutation({
    mutationFn: registerFn,
    onSuccess: (user) => {
      setUser(user);
    },
  });

  const value = {
    user: user,
    asanaLogin: loginAsanaMutation.mutateAsync,
    asanaAppLogin: loginAsanaAppMutation.mutateAsync,
    refetchUser: refetch,
    login: loginMutation.mutateAsync,
    register: registerMutation.mutateAsync,
    logout: logoutMutation.mutateAsync,
    isLoggingIn: false,
    isRegistering: registerMutation.isLoading,
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
