import { axios } from '@/lib/axios';

import { UserResponse } from '../types';

export type LoginWithAsanaDTO = {
  code: string;
};

export const loginWithAsana = (data: LoginWithAsanaDTO): Promise<UserResponse> => {
  return axios.post('/auth/asana', data);
};
