import { axios } from '@/lib/axios';

import { UserResponse } from '../types';

export type RefreshRequestProps = {
  refresh_token: string;
};

export const refreshRequest = (data: RefreshRequestProps): Promise<UserResponse> => {
  return axios.post('/refresh-token', data);
};
