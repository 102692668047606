export const WebRequestIcon = () => {
  return (
    <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false">
      <path
        d="M12.53,6.54c1.1-1.91,3.55-2.57,5.46-1.46,1.42,.82,2.15,2.38,1.98,3.91-.06,.55,.33,1.04,.88,1.11,.55,.06,1.04-.33,1.11-.88,.26-2.3-.83-4.64-2.96-5.87-2.87-1.66-6.54-.67-8.2,2.2-1.49,2.57-.85,5.79,1.37,7.62l-6.54,11.33c-.28,.48-.11,1.09,.37,1.37,.48,.28,1.09,.11,1.37-.37l7-12.12c.28-.48,.11-1.09-.37-1.37-1.91-1.1-2.57-3.55-1.46-5.46ZM6.5,29c-2.21,0-4-1.79-4-4,0-1.64,.99-3.05,2.4-3.67,.51-.22,.74-.81,.52-1.32-.22-.51-.81-.74-1.32-.52-2.12,.92-3.6,3.04-3.6,5.5,0,3.31,2.69,6,6,6,2.97,0,5.44-2.16,5.92-5h13.08c.55,0,1-.45,1-1s-.45-1-1-1H11.5c-.55,0-1,.45-1,1,0,2.21-1.79,4-4,4Zm22.47-6c1.1,1.91,.45,4.36-1.46,5.46-1.42,.82-3.13,.67-4.38-.24-.44-.33-1.07-.23-1.4,.21-.33,.44-.23,1.07,.21,1.4,1.86,1.37,4.43,1.6,6.56,.37,2.87-1.66,3.85-5.33,2.2-8.2-1.49-2.57-4.59-3.63-7.29-2.62l-6.54-11.33c-.28-.48-.89-.64-1.37-.37-.48,.28-.64,.89-.37,1.37l7,12.12c.28,.48,.89,.64,1.37,.37,1.91-1.1,4.36-.45,5.46,1.46Z"
        fill="white"
      ></path>
    </svg>
  );
};
