import tw from 'tailwind-styled-components';

import Faqs from '../Elements/FAQ/faq';
import Sidebar from '../Elements/Sidebar/Sidebar';

const Container = tw.div`
    md:pl-64 
    h-full
    
    flex 
    flex-col 
    flex-1
   
`;

export const FaqPage = () => {
  return (
    <div className="h-screen bg-gray-50">
      <Sidebar />
      <Container>
        {' '}
        <Faqs />
      </Container>
    </div>
  );
};
