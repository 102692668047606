/* eslint-disable @typescript-eslint/no-unused-vars */
import * as braze from '@braze/web-sdk';
import {
  ShieldCheckIcon,
  BoltIcon,
  Square2StackIcon,
  ComputerDesktopIcon,
} from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';
import _ from 'underscore';

import {
  TemplateCard,
  TemplateCardNotFromBackend,
  TemplateCardNotFromBackend2,
} from '@/components/Elements/Card/TemplateCard';
import Sidebar from '@/components/Elements/Sidebar/Sidebar';
import Header from '@/components/Head/Header';
import { getAllCards, getPowerUpCards, getSecurityCards } from '@/features/home';
import { Mixpanel } from '@/features/hooks/MixpanelHook';
import { useAuth } from '@/lib/auth';

import { MainLayoutTitle } from '../Elements/Header/MainLayoutTitle';
import AsanaIcon from '../Elements/Icons/asanaIcon';
import AzureIcon from '../Elements/Icons/azureIcon';
import GoogleIcon from '../Elements/Icons/googleIcon';

const Container = tw.div`
    md:pl-64 
    h-full
    flex 
    flex-col 
    flex-1
   
`;

const MainContainer = tw.main`
  flex
  flex-col
  gap-4
  bg-gray-50
  h-auto
  py-6
`;

const MainWrapper = tw.div`
  py-6
  

`;

const TitleWrapper = tw.div`
  mx-auto 
  px-4 
  sm:px-6 
  md:px-8
  lg:ml-4
 
`;

const Title = tw.div`
  text-2xl 
  font-semibold 
  text-gray-900
relative`;

const MainContent = tw.div`
  mx-auto 
  px-4 
  sm:px-6 
  md:px-8
  lg:ml-4
`;

const CardList = tw.div`
  flex 
  justify-start
  flex-wrap
  gap-8 
  gap-x-10
  
  px-2 
  py-4
  
  
`;
type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [cards, setCards] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>([]);
  const { mixpanel } = Mixpanel();
  braze.initialize('52b5fed9-e1d0-4be3-9d85-572202db7d38', {
    baseUrl: 'sdk.fra-02.braze.eu',
  });
  const { user } = useAuth();
  if (user?.id) {
    braze.changeUser(user.id);
  }

  braze.openSession();

  const getTemplates = async () => {
    const data = await getAllCards();
    setCards(data);
  };

  useEffect(() => {
    getTemplates();
    mixpanel.track('Home View', { user: user?.id, page: 'Home Page Viewed', email: user?.email });
  }, []);

  return (
    <div className="bg-gray-50 h-screen">
      <Sidebar />
      <Container>
        <Header handleSearch={setInputValue} />

        {cards.length !== 0 && (
          <MainContainer>
            {/* <div className='ml-12 mb-12'>
            On this page you will see the Templates. After clicking the Use Template button, you will see a more detailed page about Templates.
            </div> */}
            <div>
              <TitleWrapper>
                <MainLayoutTitle name="Security" Icon={ShieldCheckIcon} />
              </TitleWrapper>
              <MainContent>
                <CardList>
                  {_.sortBy(cards, 'status')
                    .reverse()
                    .filter((card: any) => card.name.includes(inputValue))
                    .filter((card: any) => card.type === 'security')
                    .map((template: any) => (
                      <TemplateCard
                        Icon={ShieldCheckIcon}
                        key={template.id}
                        id={template.id}
                        title={template.name}
                        status={template.status}
                        description={template.description}
                      />
                    ))}
                </CardList>
              </MainContent>
            </div>
            <div>
              <TitleWrapper>
                <MainLayoutTitle name="AI" Icon={ComputerDesktopIcon} />
              </TitleWrapper>
              <MainContent>
                <CardList>
                  <TemplateCardNotFromBackend2
                    Icon={ComputerDesktopIcon}
                    title="ChatGPT Plugin"
                    description="Ask ChatGPT for any recipes to do anything and let the plugin creates project and task."
                    status={false}
                    Platform={AsanaIcon}
                  />
                  <TemplateCardNotFromBackend2
                    Icon={ComputerDesktopIcon}
                    title="ChatGPT Assistant"
                    description="Ask anything ChatGPT Assistant in your Asana. Wherever you mention the assistant, it is ready to help you."
                    status={false}
                    Platform={AsanaIcon}
                  />
                </CardList>
              </MainContent>
            </div>
            <div>
              <TitleWrapper>
                <MainLayoutTitle name="Templates" Icon={Square2StackIcon} />
              </TitleWrapper>
              <MainContent>
                <CardList>
                  {_.sortBy(cards, 'status')
                    .reverse()
                    .filter((card: any) => card.name.includes(inputValue))
                    .filter((card: any) => card.type === 'template')
                    .map((template: any) => (
                      <TemplateCard
                        Icon={Square2StackIcon}
                        key={template.id}
                        id={template.id}
                        title={template.name}
                        status={template.status}
                        description={template.description}
                      />
                    ))}
                  <TemplateCardNotFromBackend
                    Icon={Square2StackIcon}
                    title="Auto provisioning and deprovisioning for Azure"
                    description="It allows you to remove a Azure user when they are inactive or leave your domain."
                    status={false}
                    Platform={AzureIcon}
                  />
                  <TemplateCardNotFromBackend
                    Icon={Square2StackIcon}
                    title="Auto provisioning and deprovisioning for Google"
                    description="It allows you to remove a Google user when they are inactive or leave your domain."
                    status={false}
                    Platform={GoogleIcon}
                  />
                </CardList>
              </MainContent>
            </div>

            <div>
              <TitleWrapper>
                <MainLayoutTitle name="Power-Ups" Icon={BoltIcon} />
              </TitleWrapper>
              <MainContent>
                <CardList>
                  {_.sortBy(cards, 'status')
                    .reverse()
                    .filter((card: any) => card.name.includes(inputValue))
                    .filter((card: any) => card.type === 'power-up')
                    .map((template: any) => (
                      <TemplateCard
                        Icon={BoltIcon}
                        key={template.id}
                        template_id={template.template_id}
                        title={template.name}
                        id={template.id}
                        status={template.status}
                        description={template.description}
                      />
                    ))}
                </CardList>
              </MainContent>
            </div>
          </MainContainer>
        )}
      </Container>
      {children}
    </div>
  );
};

export default MainLayout;
