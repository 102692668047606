import { uuid } from 'uuidv4';
import * as z from 'zod';

import AsanaIcon from '@/assets/AsanaIcon';
import { Button, Link } from '@/components/Elements';
import { Checkbox } from '@/components/Elements/Checkbox';
import { Divider } from '@/components/Elements/Divider';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';

const schema = z.object({
  email: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn } = useAuth();
  const generatedState = uuid();
  window.localStorage.setItem('asanaAppAuthState', generatedState);

  return (
    <div>
      <Button
        isLoading={isLoggingIn}
        type="submit"
        className="w-full"
        variant="white"
        onClick={() => {
          window.location.replace(
            `https://app.asana.com/-/oauth_authorize?response_type=code&client_id=${process.env.REACT_APP_ASANA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ASANA_REDIRECT_URI}&state=${generatedState}`
          );
        }}
      >
        <AsanaIcon />
      </Button>
      <div className="mt-6 relative">
        <Divider text="or" link={<Link to="/auth/register"> Register</Link>} />
      </div>
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Checkbox label="Remember me" />
              </div>
              <Link to="/forget-password">Forgot Your Password?</Link>
            </div>
            <div>
              <Button isLoading={isLoggingIn} type="submit" className="w-full">
                Log in
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
