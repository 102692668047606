import { AsanaAuthForApp, AuthForApp } from '@/features/auth';
import { PublicRulesPage } from '@/features/home/components/Rules/publicRulesPage';
import { lazyImport } from '@/utils/lazyImport';
const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes');
const { AsanaAuthRoutes } = lazyImport(() => import('@/features/auth'), 'AsanaAuthRoutes');
export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/app/auth',
    element: <AuthForApp />,
  },
  {
    path: '/asana-app/auth/*',
    element: <AsanaAuthForApp />,
  },

  { path: '/asana/auth', element: <AsanaAuthRoutes /> },
  { path: '/rules', element: <PublicRulesPage /> },
];
