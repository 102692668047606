import clsx from 'clsx';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

export const Link = ({ className, children, ...props }: LinkProps) => {
  return (
    <RouterLink
      className={clsx(
        'to-transparent text-sm font-semibold focus:outline-none  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        className
      )}
      {...props}
    >
      {children}
    </RouterLink>
  );
};
