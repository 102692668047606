import tw from 'tailwind-styled-components';

import WorkflowCard from './Card/WorkflowCard';
import WorkflowDivider from './Divider/WorkflowDivider';
import TriggerForm from './Form/WorkflowForm';
import WorkflowHeader from './Header/WorkflowHeader';

const WorkflowContainer = tw.div`
flex-auto 
h-full 
bg-gray-50`;

const WorkflowHeaderText = tw.h2`
mt-6 pl-7 pt-2 text-3xl font-extrabold text-gray-900`;

export const Workflow = () => {
  return (
    <WorkflowContainer>
      <WorkflowHeader />
      <WorkflowHeaderText>Create a Workflow</WorkflowHeaderText>
      <WorkflowCard>
        <TriggerForm
          header="Trigger"
          option={['When a form submitted from Asana Forms In the project']}
        />
        <br />
        <br />
        <TriggerForm header="Workspace" option={'Omtera'} />
        <br />
        <TriggerForm header="Project" option={'Toolbox'} />
      </WorkflowCard>
      <br />
      <br />
      <WorkflowDivider />
      <WorkflowCard>
        <TriggerForm header="Action" option={['Send With Gmail']} />
        <br />
        <br />
        <TriggerForm header="Select a custom field" option={'Employee Email'} />
      </WorkflowCard>
    </WorkflowContainer>
  );
};
