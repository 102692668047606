import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { FaqPage } from '@/components/Layout/FaqPage';
import { GmailAuthRoutes } from '@/features/auth';
import { SubscriptionSuccessRoutes } from '@/features/home';
import { RulesPage } from '@/features/home/components/Rules/rulePage';
// import { IntegrationRoutes } from '@/features/Integration';
import { TestEnvironmentLayout } from '@/features/workflow/components/TestEnviroment/TestEnviroment';
import { WorkflowRouteList } from '@/features/workflowlist';
import { lazyImport } from '@/utils/lazyImport';

const { WorkflowRoutes } = lazyImport(() => import('@/features/workflow'), 'WorkflowRoutes');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full sm: flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
  },
  { path: '/workflow/*', element: <WorkflowRoutes /> },
  { path: '/gmail/*', element: <GmailAuthRoutes /> },
  { path: '/your/*', element: <WorkflowRouteList /> },
  { path: '/check/*', element: <SubscriptionSuccessRoutes /> },
  { path: '/frequently-asked-questions', element: <FaqPage /> },
  { path: '/rules', element: <RulesPage /> },
  { path: '/test-enviroment', element: <TestEnvironmentLayout /> },
  // { path: '/*', element: <IntegrationRoutes /> },
];
