import tw from 'tailwind-styled-components';
// @ts-ignore

const StyledCheckBox = tw.input`
focus:ring-indigo-500 
h-4 w-4 
text-indigo-600 
border-gray-300 rounded
inline-flex


`;

export const Checkbox = ({ label }: any) => {
  return (
    <div className="flex items-center h-5">
      <StyledCheckBox type="checkbox" />
      <div className="ml-1.5 text-sm">
        <label className="text-sm font-medium text-gray-700 inline-flex">{label}</label>
      </div>
    </div>
  );
};
