import { CalendarIcon, LinkIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import tw from 'tailwind-styled-components';

import { Button } from '@/components/Elements';
import ConfirmationModal from '@/components/Elements/Modals/ConfirmationModal';
import Sidebar from '@/components/Elements/Sidebar/Sidebar';
import { Tag } from '@/components/Tags/tags';

import BreadCrumb from '../CommonComponents/BreadCrumbs';

import { DetailArticle } from './DetailArticle';

export const WorkflowDetailPage = ({ workflow }: any) => {
  const [open, setOpen] = useState(false);
  console.log(workflow, 'workflow');

  const onClick = () => {
    window.open(`https://app.asana.com/0/${workflow.parent_resource_id}`);
  };
  const Container = tw.div`
    md:pl-64 
    h-full
    flex 
    gap-12
    
    
   
`;

  const pages = [
    { name: 'Workflows', href: '/your/workflows', current: false },
    { name: workflow.id, href: '/', current: true },
  ];

  return (
    <div className="bg-gray-50 h-screen ">
      <Sidebar />
      <div className="pl-12 pt-12">
        <Container>
          <div className="flex-col max-w-3xl pl-20 ">
            <BreadCrumb pages={pages} />
            {open ? <ConfirmationModal open={open} setOpen={setOpen} key_={workflow.key} /> : ''}
            <span className="text-3xl">
              #{workflow.id} {workflow.template_name} for{' '}
              <p className="inline-flex text-indigo-600">{workflow.parent_resource_name}</p>
            </span>
            <span className="flex text-3xl">{workflow.parent_resource_type}</span>
            <span>
              <div className="inline-flex">
                <p className=" mt-2 inline-flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>Template: {workflow.template_name}</p>
                </p>{' '}
                <p className=" ml-2 mt-2 inline-flex items-center text-sm text-gray-500">
                  <MapPinIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>Created at {workflow.created_at}</p>
                </p>{' '}
              </div>
            </span>
          </div>
          <div className="mt-10">
            <Tag color="text-green-800 bg-green-100" text="Running" />
          </div>
          <div className="mt-8">
            <Button
              className=""
              size="sm"
              endIcon={<LinkIcon className="h-4 w-4" aria-hidden="true" />}
              variant={'white'}
              onClick={onClick}
            >
              Go to project
            </Button>
          </div>
        </Container>
        <Container>
          <div className="h-8 ml-20 mt-4 inline-flex">
            <Button
              className="py-2 px-2 text-white hover:bg-red-600 bg-red-500"
              size="sm"
              variant={'white'}
              onClick={() => {
                setOpen(true);
              }}
            >
              Delete
            </Button>
            {/* <Button className="" size="sm" variant={'white'} onClick={() => {}}>
              Readme
            </Button> */}
          </div>
        </Container>
        <Container>
          <DetailArticle template_name={workflow.template_name} />
        </Container>
      </div>
    </div>
  );
};
