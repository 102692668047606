// import { CheckIcon } from '@heroicons/react/20/solid';

import { useState } from 'react';

// // import { postCreateCheckoutPage } from '../../api/createCheckoutPage';
import OfferForm from './OfferForm';

const tiers = [
  {
    name: 'Contact Us!',
    id: 'price_1NWIgzFJpkl9wMi7RQfR2dmd',
    price: { monthly: 'Custom Price', annually: '$144' },
    description: 'Get offer for exclusive features and custom developments!',
    features: ['Access to all Features', '20 Credits'],
    mostPopular: false,
  },
  // // {
  // //   name: 'Basic',
  // //   id: 'price_1NWIgzFJpkl9wMi7RQfR2dmd',
  // //   price: { monthly: '$5.99', annually: '$144' },
  // //   description: 'Good for anyone who is self-employed and just getting started.',
  // //   features: ['Access to all Features', '20 Credits'],
  // //   mostPopular: false,
  // // },
  // {
  //   name: 'Lite',
  //   id: 'price_1NWIgzFJpkl9wMi7kJo1TqFe',
  //   price: { monthly: '$9.99', annually: '$288' },
  //   description: 'Perfect for small sized businesses.',
  //   features: ['Access to all Features', '40 Credits', 'Live Support'],
  //   mostPopular: true,
  // },
  // {
  //   name: 'Premium',
  //   id: 'price_1NWIgzFJpkl9wMi7L9jD6ixm',
  //   price: { monthly: '$14.99', annually: '$576' },
  //   description: 'Dedicated support and infrastructure for your company.',
  //   features: ['Access to all Features', '100 Credits', 'Prior Live Support'],

  //   mostPopular: false,
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Packages() {
  const [open, setOpen] = useState(false);
  const onClick = async () => {
    setOpen(true);
    // const data = await postCreateCheckoutPage({ plan: price });
    // window.location.replace(data['url']);
  };

  return (
    <div className="h-screen bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-4xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Get an exclusive offer for you!
          </p>
          <OfferForm open={open} setOpen={setOpen} />
        </div>

        <div className="isolate mx-auto mt-10 grid  grid-cols-1">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8 xl:p-10'
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                    'text-3xl text-center font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-8 text-xl leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-10 text-center  gap-x-1">
                <span className="text-4xl  font-bold tracking-tight text-gray-900">
                  {tier.price.monthly}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600"></span>
              </p>
              <button
                onClick={() => onClick()}
                className={classNames(
                  !tier.mostPopular
                    ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-10 w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                Get an offer
              </button>
              <div
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
