import mixpanel from 'mixpanel-browser';

export const Mixpanel = () => {
  mixpanel.init('765a8af66774a271626bdf0c2d274402', {
    debug: true,
    track_pageview: false,
    persistence: 'localStorage',
  });
  return { mixpanel };
};
