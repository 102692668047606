import { BoltIcon } from '@heroicons/react/24/solid';

export const RulesCard = ({ TriggerIcon, rule_name, description }) => {
  return (
    <div className="bg-white h-56 shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="inline-flex">
          <div className="h-9 w-9 bg-indigo-600 rounded-lg ">
            <div className="h-6 w-6 mx-auto py-1.5 text-base font-semibold leading-6 text-white">
              <TriggerIcon />
            </div>
          </div>
          <div className="h-6 w-6 mx-4 py-1.5 text-base font-semibold leading-6 text-yellow-400">
            <BoltIcon />
          </div>
          <div className="h-8 w-8 mx-auto py-1.5 text-base font-semibold leading-6 text-white">
            <img src="https://i.ibb.co/X3RMnVk/logo.png" alt="logo" />
          </div>
          <p className="mx-1 my-2">{rule_name}</p>
        </div>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            <p>{description}</p>
          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center"></div>
        </div>
      </div>
    </div>
  );
};
