import { useState, useEffect } from 'react';

import { getWorkflows } from '../workflowlist/api/getWorkflows';

export const useWorkflow = () => {
  const [workflows, setWorkflows] = useState<any>([]);

  // For workspace
  useEffect(() => {
    const fetchWorkflows = async () => {
      const workflow = await getWorkflows();
      setWorkflows(workflow);
    };

    fetchWorkflows();
  }, []);

  return { workflows, setWorkflows };
};
