import { Spinner } from '@/components/Elements';

export default function BillingCard({ subscription }) {
  return (
    <div className="max-w-2xl bg-white shadow sm:rounded-lg">
      <div className="px-4 h-72 py-6 sm:p-6 ">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900">Your Plan</h3>
        <div className="mt-12 text-3xl text-gray-500">
          <p className="text-center">
            {subscription ? subscription.name : <Spinner className="mx-auto " size="custom" />}
          </p>
        </div>
        <div className="mt-20">
          <button
            type="button"
            onClick={() => window.location.replace('https://app.workino.co/your/settings/packages')}
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Change plan & See other plans
          </button>
        </div>
      </div>
    </div>
  );
}
