import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { uuid } from 'uuidv4';

import AsanaIcon from '@/assets/AsanaIcon';
import { Button } from '@/components/Elements';
import { useAuth } from '@/lib/auth';

export default function AsanaConnectionComponent({ setAsanaModal, asanaModal }) {
  const { login, isLoggingIn } = useAuth();
  console.log(login);
  const generatedState = uuid();
  window.localStorage.setItem('asanaAppAuthState', generatedState);

  return (
    <Transition.Root show={asanaModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setAsanaModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Please connect your Asana account
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        For using templates you must connect your Asana account
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Button
                    isLoading={isLoggingIn}
                    type="submit"
                    className="w-full"
                    variant="white"
                    onClick={() => {
                      window.location.replace(
                        `https://app.asana.com/-/oauth_authorize?response_type=code&client_id=${process.env.REACT_APP_ASANA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ASANA_REDIRECT_URI}&state=${generatedState}`
                      );
                    }}
                  >
                    <AsanaIcon />
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
