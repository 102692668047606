import tw from 'tailwind-styled-components';

interface WorkflowProps {
  children: React.ReactNode;
}

const WorkflowCardContainer = tw.div`
p-4
mx-80
mt-20 
w-2/3	
bg-white 
rounded-lg 
border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700
`;

const WorkflowCard = ({ children }: WorkflowProps) => {
  return <WorkflowCardContainer>{children}</WorkflowCardContainer>;
};

export default WorkflowCard;
