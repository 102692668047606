import { axios } from '@/lib/axios';

import { GmailAuthInterface, GmailUrlResponse } from '../types';

export const getGmailURL = (data: GmailAuthInterface): Promise<GmailUrlResponse> => {
  return axios.get('/auth/google/auth-url', {
    params: {
      redirect_uri: data.url,
      scopes: data.scope,
    },
  });
};
