import { Spinner } from '@/components/Elements';

export default function Remaning({ remaining }) {
  return (
    <div className="max-w-2xl bg-white shadow sm:rounded-lg">
      <div className="px-4 h-72 py-6 sm:p-6 ">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900">Your Remaining Credits</h3>
        <div className="mt-12 text-3xl text-gray-500">
          <p className="text-center text-7xl font-bold">
            {remaining ? remaining : <Spinner className="mx-auto" size="custom" />}
          </p>
        </div>
        <div className="mt-20"></div>
      </div>
    </div>
  );
}
