import clsx from 'clsx';
import * as React from 'react';
import tw from 'tailwind-styled-components';

import { Spinner } from '@/components/Elements/Spinner';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'white' | 'inactive';
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
} & IconProps;

const StyledButton = tw.button<ButtonProps>`
    w-full 
    flex 
    items-center
    justify-center
    py-2 
    px-4 
    border 
    border-transparent 
    rounded-md 
    shadow-sm 
    text-sm 
    font-medium 
    text-white 

    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    focus:ring-indigo-500

    ${({ variant }: ButtonProps) => {
      switch (variant) {
        case 'primary':
          return 'text-white bg-green-500 hover:bg-green-600';
        case 'secondary':
          return 'text-white bg-indigo-500 hover:bg-indigo-600';
        case 'inactive':
          return 'text-gray-400 bg-indigo-100 hover:bg-indigo-200';
        case 'white':
          return 'text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 shadow-sm';

        default:
          return 'text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 shadow-sm';
      }
    }}

    ${({ size }: ButtonProps) => {
      switch (size) {
        case 'sm':
          return 'py-2 px-4 text-sm';
        case 'md':
          return 'py-2 px-6 text-md';
        case 'lg':
          return 'py-3 px-8 text-lg';
        default:
          return 'py-2 px-6 text-md';
      }
    }}
`;

type IconProps =
  | { startIcon: any; endIcon?: never }
  | { endIcon: any; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        type={type}
        variant={variant}
        size={size}
        className={clsx(className)}
        {...props}
      >
        {isLoading && <Spinner size="sm" className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';
