import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import storage from '@/utils/storage';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const navigate = useNavigate();
  const checkToken = async () => {
    navigate('/app');
    console.log('asdfsfadsdfaadfss');
  };

  useEffect(() => {
    if (storage.getToken()) {
      checkToken();
    }
  }, []);

  return (
    <Layout title="Log in to your account">
      <LoginForm onSuccess={() => navigate('/app')} />
    </Layout>
  );
};
