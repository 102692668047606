function AzureIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <g>
        <path fill="#F25022" d="M30.905 30.904H246.553V246.554H30.905z"></path>
        <path fill="#7FBA00" d="M265.446 30.904H481.09400000000005V246.554H265.446z"></path>
        <path fill="#00A4EF" d="M30.905 265.444H246.553V481.095H30.905z"></path>
        <path fill="#FFB900" d="M265.446 265.444H481.09400000000005V481.095H265.446z"></path>
      </g>
    </svg>
  );
}

export default AzureIcon;
