import { axios } from '@/lib/axios';

import { AsanaAuthInterface, UserResponse } from '../types';

export const getAsanaToken = (data: AsanaAuthInterface): Promise<UserResponse> => {
  return axios.post('/auth/asana', data);
};

export const getAsanaAppToken = (data: AsanaAuthInterface): Promise<UserResponse> => {
  return axios.post('/auth/asana-app', data);
};
