import { Route, Routes, useNavigate } from 'react-router-dom';

import { AsanaAppAuth, AsanaAppAuthCallback } from '../components/AsanaAppAuth';
import { AsanaAuth } from '../components/AsanaAuth';
import { GmailAuth } from '../components/GmailAuth';
import { GmailCallback } from '../components/GmailCallback';

import { Login } from './Login';
import { Register } from './Register';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="register" element={<Register />} />

      <Route path="" element={<Login />} />
    </Routes>
  );
};

export const AuthForApp = () => {
  return (
    <Routes>
      <Route path="" element={<AsanaAppAuth />} />
    </Routes>
  );
};
export const AsanaAuthForApp = () => {
  return (
    <Routes>
      <Route path="" element={<AsanaAppAuthCallback />} />
    </Routes>
  );
};

export const AsanaAuthRoutes = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="" element={<AsanaAuth onSuccess={() => navigate('/app')} />} />
    </Routes>
  );
};

export const GmailAuthRoutes = () => {
  return (
    <Routes>
      <Route path="auth" element={<GmailAuth />} />
      <Route path="auth/callback" element={<GmailCallback />} />
    </Routes>
  );
};
