import { useState, useEffect } from 'react';
import tw from 'tailwind-styled-components';

import { getGmailURL } from '../api/getGmailURI';
import { GmailUrlResponse } from '../types';

const ButtonWrapper = tw.button`
inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`;
const TextWrapper = tw.p`
   text-gray-700
`;
const ImgWrapper = tw.img`
    w-7
    h-7
    mt-1
`;

export const GmailAuth = () => {
  const [url, setUrl] = useState<GmailUrlResponse>();

  const getGmailURLFn = async () => {
    const redirectURI = {
      url: 'http://localhost:3000/gmail/auth/callback',
      scope: 'https://www.googleapis.com/auth/calendar',
    };

    const data = await getGmailURL(redirectURI);
    setUrl(data);
    console.log(data);
  };
  useEffect(() => {
    getGmailURLFn();
    console.log(url);
  }, []);

  return (
    <ButtonWrapper
      onClick={() => {
        url ? window.location.replace(`${url.authorization_url}`) : console.log('başarısız');
      }}
    >
      <ImgWrapper
        src="https://upload.wikimedia.org/wikipedia/commons/archive/5/53/20210618182605%21Google_%22G%22_Logo.svg"
        alt="google"
      ></ImgWrapper>
      <TextWrapper>Connect With Google</TextWrapper>
    </ButtonWrapper>
  );
};
