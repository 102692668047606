import { axios } from '@/lib/axios';

import { TemplateResponse } from '../types';

export const getAllCards = (): Promise<TemplateResponse> => {
  return axios.get('/get-all-templates');
};

export const getPowerUpCards = (): Promise<TemplateResponse> => {
  return axios.get('/get-power-ups');
};

export const getSecurityCards = (): Promise<TemplateResponse> => {
  return axios.get('/get-security');
};
