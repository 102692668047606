const CustomButton = ({ children, onClick, option = 'bg-indigo-600 hover:bg-indigo-700' }) => {
  return (
    <button
      onClick={() => onClick()}
      className={`inline-flex items-center rounded-md border border-transparent  ${option}  px-4 py-2 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
    >
      {children}
    </button>
  );
};

export default CustomButton;
