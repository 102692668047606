import { useEffect } from 'react';

import { createTestWebhook } from './createTestWebhook';

export const TestEnvironmentLayout = () => {
  const webhookData = {
    action: ['changed'],
    parent_resource_id: '1201999930609840',
    parent_resource_type: 'project',
    resource_type: 'task',
    sub_resource_type: 'task',
    target_path: 'recieve_test_webhook',
    template: 1,
    configuration: ['1199881345422090', '1203479192414496'],
  };

  useEffect(() => {
    createTestWebhook(webhookData);
  });
  return <div>Hello World!</div>;
};
