import * as React from 'react';

import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const Layout = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Head title={title} />

      <div className="h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <img className="h-12 w-84" src="https://i.imgur.com/qrFKnUV.png" alt="" />

            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{title}</h2>
            <div className="mt-8">{children}</div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://i.ibb.co/rGgdfXH/Whats-App-Image-2022-10-18-at-16-16-12.jpg"
            alt=""
          />
        </div>
      </div>
    </>
  );
};
