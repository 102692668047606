import { BoltIcon, CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

import { RulesCard } from './rulesCard';
import { WebRequestIcon } from './webReq';

const posts = [
  {
    id: 1,
    title: 'Sync Subtasks',
    description:
      'This rule provides task management by automating the completion of subtasks when their parent task is completed. Simultaneously, the parent task will be marked as complete once all its subtasks are finished. ',
    Icon: CheckCircleIcon,
  },
  {
    id: 2,
    title: 'Add Unique Task ID',
    description:
      'By implementing this rule, Asana will generate unique IDs for all of your tasks, enhancing your ability to efficiently track and effortlessly access them.',
    Icon: PlusCircleIcon,
  },
  {
    id: 3,
    title: 'Custom Field Alarm',
    description:
      'This rule, it allows you to filter and create triggers according to the data type (text, number, enum) of your custom field. This action allows you to create a trigger and action you choose will work with this condition.',
    Icon: WebRequestIcon,
  },
  {
    id: 4,
    title: 'Connect Asana Workspaces',
    description:
      'Using this rule, you can consolidate tasks from all of your Asana workspaces into a single project. Additionally, you have the capability to track both newly added tasks and completed tasks within this project.',
    Icon: BoltIcon,
  },
  {
    id: 5,
    title: 'Guest Management',
    description:
      'With this rule, you create a Guest Management project. From this project, you can access detailed information about guests, including newly arrived guests. You can remove these guests from the workspace using custom fields within the project.      ',
    Icon: BoltIcon,
  },
  {
    id: 6,
    title: 'User Management',
    description:
      'With this rule, you create a User Management project. From this project, you can access detailed information about users, including newly arrived users. You can remove these users from the workspace using custom fields within the project.      ',
    Icon: BoltIcon,
  },
  {
    id: 7,
    title: 'Send Discord Message    ',
    description: 'With this rule, you can send messages from Asana to your Discord channel.      ',
    Icon: BoltIcon,
  },
  {
    id: 8,
    title: 'Create Asana Template AI    ',
    description:
      'Using AI, a portfolio is generated based on the provided description. Within this portfolio, projects and tasks are created and automatically assigned.',
    Icon: BoltIcon,
  },
  {
    id: 9,
    title: 'Task Alarm ',
    description:
      'This rule, it allows you to filter and create triggers according to the task description or name. This action allows you to create a trigger and action you choose will work with this condition',
    Icon: BoltIcon,
  },
];

export const PublicRulesPage = () => {
  return (
    <div className="bg-white h-full py-12 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Rules</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Learn how to use our rules for your business.
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
              <div className="flex items-center gap-x-4 text-xs">
                <a
                  href="#"
                  className="relative z-10 rounded-full bg-green-600 px-3 py-1.5 font-medium text-white hover:bg-red-300"
                >
                  Rule
                </a>
              </div>
              <RulesCard
                rule_name={post.title}
                description={post.description}
                TriggerIcon={post.Icon}
              />{' '}
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};
