import Axios, { AxiosRequestConfig } from 'axios';

import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const jwt = storage.getToken();
  console.log('JWT', jwt);
  if (jwt) {
    console.log('accessToken', jwt['accessToken'], jwt);
    config.headers.authorization = `Bearer ${jwt['accessToken']}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URI,
});

export const refreshAccessTokenFn = async () => {
  const jwt = storage.getToken();

  const response = await axios.post('auth/refresh', { refresh_token: jwt['refreshToken'] });

  console.log('data', response?.data);
  console.log('res', response);

  return response;
};

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    const message = error.response?.data?.detail || error.detail;

    if (message.includes('Token expired.') && !originalRequest._retry) {
      originalRequest._retry = true;
      const jwt = await refreshAccessTokenFn();
      storage.setToken(jwt);
      return axios(originalRequest);
    }
    if (message.includes('User not found') && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log('user found');
      storage.clearToken();
      window.location.replace('/');
      return axios(originalRequest);
    }

    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Error',
      message,
    });

    return Promise.reject(error);
  }
);
