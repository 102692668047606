import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { useAuth } from '@/lib/auth';

export default function Avatar() {
  const { logout, user } = useAuth();
  const name = user?.email.slice(0, 2).toUpperCase();
  const userNavigation = [{ name: 'Sign out', onClick: () => logout() }];

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span className="sr-only">Open user menu</span>
          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
            <span className="text-sm font-medium leading-none text-white">{name}</span>
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              <div className="block w-36 item-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                <button onClick={item.onClick}>{item.name}</button>
              </div>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
