import React from 'react';
import tw from 'tailwind-styled-components';

const LineWrapper = tw.div`
absolute inset-0 flex items-center`;

const DividerLine = tw.div`
w-full border-t border-gray-300
`;

const ContentWrapper = tw.div`
relative flex justify-center text-sm
`;

const DividerText = tw.span`
px-2 bg-white text-gray-500`;

interface DividerProps {
  link: React.ReactNode;
  text: string;
}

export const Divider = ({ link, text }: DividerProps) => {
  return (
    <>
      <LineWrapper>
        <DividerLine />
      </LineWrapper>
      <ContentWrapper>
        <DividerText>
          {text}
          {link}
        </DividerText>
      </ContentWrapper>
    </>
  );
};
