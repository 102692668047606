import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import tw from 'tailwind-styled-components';

import Avatar from '../Elements/Avatar';
import { SidebarNotification } from '../Notifications/SidebarNotification';

// import Avatar from '../Elements/Avatar';

const HeaderWrapper = tw.div`
    sticky 
    top-0 
    z-10 
    flex-shrink-0 
    flex 
    h-16 
    bg-white shadow
`;

const SearchContainer = tw.div`
    flex-1 
    px-4 
    flex 
    justify-between
`;

const SearchWrapper = tw.div`
    flex-1 
    flex
`;

const SearchForm = tw.form`
    w-full 
    flex 
    md:ml-0
`;

const Header = ({ handleSearch }: any) => {
  return (
    <HeaderWrapper>
      <SearchContainer>
        <SearchWrapper>
          <SearchForm>
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
              </div>

              <input
                id="search-field"
                className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                placeholder="Search"
                type="search"
                name="search"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <div className="absolute inset-y-0 right-0 flex items-center ">
                <SidebarNotification />
                <Avatar />
              </div>
            </div>
          </SearchForm>
        </SearchWrapper>
      </SearchContainer>
    </HeaderWrapper>
  );
};

export default Header;
