import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GmailCallbackButton } from '@/components/Elements/Button/GmailCallbackButton';

import { gmailTokenExchange } from '../api/gmailTokenExchange';

// import { GmailCallbackInterface } from '../types';

export const GmailCallback = () => {
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState<any>();
  const [scope, setScope] = useState<any>();
  const [googleState, setGoogleState] = useState<any>();
  const redirectURI = 'http://localhost:3000/gmail/auth/callback';
  const gmailTokenExchangeFn = async () => {
    const data = {
      redirect_uri: redirectURI,
      scopes: scope,
      auth_in: code,
    };
    await gmailTokenExchange(data);
  };
  useEffect(() => {
    const getCode = searchParams.get('code');
    const getScope = searchParams.getAll('scope');
    const getState = searchParams.get('state');
    setCode(getCode);
    setScope(getScope[0]);
    setGoogleState(getState);
    console.log(code, scope, googleState);
    console.log();
    if (code) {
      const data = gmailTokenExchangeFn();
      console.log(data);
    }
  }, [code]);

  return <GmailCallbackButton />;
};
