import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';

import Sidebar from '@/components/Elements/Sidebar/Sidebar';

import { getRemaining } from '../../api/getRemaining';
import { getSubscription } from '../../api/getSubscription';

import BillingCard from './BillingCard';
import BillingHistory from './BillingHistory';
import Remaning from './Remaining';
import SettingsTabs from './SettingsTab';

const tabs = [
  { name: 'My Account', href: '/your/settings', current: false },

  { name: 'Billing', href: '/your/settings/billing', current: true },
  { name: 'Packages', href: '/your/settings/packages', current: false },
];

export default function Billing() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [subscription, setSubscription] = useState();
  const [remaining, setRemaining] = useState();

  const getSubscriptionFunc = async () => {
    setSubscription(await getSubscription());
    setRemaining(await getRemaining());
  };

  useEffect(() => {
    getSubscriptionFunc();
  }, []);
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-900">
        <body class="h-full">
        ```
      */}
      <div className="h-screen bg-gray-50">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 xl:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-black" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <Sidebar />

        <div className="xl:pl-64">
          {/* Sticky search header */}

          <main>
            <SettingsTabs tabs={tabs} />
            {/* Settings forms */}
            <div className="px-12 py-24 grid grid-cols-2 ">
              <div className="cols-span-1">
                {' '}
                <BillingCard subscription={subscription} />
              </div>{' '}
              <div className="cols-span-1">
                {' '}
                <Remaning remaining={remaining} />
              </div>{' '}
            </div>
            <div className="px-12 grid grid-cols-1  ">
              <div className="cols-span-full">
                {' '}
                <BillingHistory />
              </div>{' '}
            </div>{' '}
          </main>
        </div>
      </div>
    </>
  );
}
